/* eslint-disable no-unused-vars */
import { io } from "socket.io-client";
import store from "../store";
import { countNotif } from "@/api/notif";

class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection() {
    const connect = io(process.env.VUE_APP_SOCKET_ENDPOINT, { secure: false });
    

    connect.on("connect", function () {
      let room_id = store.state.profile.customer_id.toString();
      connect.emit("room", room_id);
    });

    connect.on("socket-notification", async (data) => {
      const audio = new Audio(require("@/assets/audio/notif.mp3"));
      audio.play();

      const res = await countNotif();
      let count;
      if (res.status === 200) {
        count = res.data.data;
      } else if (res.status === 404) {
        count = 0;
      }
      await store.dispatch("setNotifCount", { count: count }, { root: true });
      // console.log(data, "SOCKET")
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default new SocketioService();
