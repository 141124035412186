<template>
  <v-app class="font-lato">
    <router-view />
  </v-app>
</template>

<script>
import SocketioService from "@/socket/socketio.service.js"
export default {
  name: "App",
  metaInfo() {
    let notifCount = this.$store.state.notif.count > 0 ? `(${this.$store.state.notif.count})` : '';
    return {
      title: notifCount > 99 ? `${notifCount}+` : notifCount,
      titleTemplate: '%s MyRocknet',
    };
  },
  data: () => ({
    //
  }),
  created() {
    SocketioService.setupSocketConnection()
  },
  beforeUnmount() {
    SocketioService.disconnect()
  },
}
</script>
