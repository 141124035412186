import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from 'js-cookie';

Vue.use(Vuex);

// const expiredTime = new Date(new Date().getTime() + 1000 * 60 * 60); // 60 menit
const expiredTime = 1/24 // 60 menit

export default new Vuex.Store({
  state: {
    token: "",
    profile: {
      customer_name: "",
      customer_id: "",
      customer_email: "",
      customer_phone: "",
      customer_otp: "",
      customer_status: "",
    },
    notif: {
      count: 0,
    },
  },
  mutations: {
    // token
    SET_AUTH_TOKEN(state, payload) {
      state.token = payload.token;
      state.profile.customer_id = ''
    },
    REMOVE_TOKEN(state) {
      state.token = "";
    },
    // profile
    SET_PROFILE_USER(state, payload) {
      // profile
      state.profile.customer_name = payload.customer_name;
      state.profile.customer_id = payload.customer_id;
      state.profile.customer_email = payload.customer_email;
      state.profile.customer_phone = payload.customer_phone;
      state.profile.customer_otp = payload.customer_otp;
      state.profile.customer_status = payload.customer_status;
    },
    SET_PROFILE_ID(state, payload) {
      // profile
      state.profile.customer_id = payload.id;
    },
    REMOVE_PROFILE(state) {
      state.profile.user_fname = "";
      state.profile.user_id = "";
      state.profile.user_registerby = "";
      state.profile.user_status = "";
      state.profile.users_emails.is_verified = "false";
      state.profile.users_emails.user_email = "";
      state.profile.users_photos = "";
      state.profile.users_whatsapps.is_verified = "false";
      state.profile.users_whatsapps.user_whatsapp = "";
      state.profile.user_expertise = false
    },
    SET_PROFILE_AVATAR(state, payload) {
      state.profile.users_photos = payload.users_photos;
    },

    SET_PROFILE_BIODATA(state, payload) {
      state.profile.user_fname = payload.user_fname;
    },

    SET_PROFILE_EMAIL(state, payload) {
      state.profile.users_emails.user_email = payload.user_email;
    },

    SET_PROFILE_WHATSAPP(state, payload) {
      state.profile.users_whatsapps.user_whatsapp = payload.user_whatsapp;
    },
    SET_WHATSAPP_VERIFY(state, payload) {
      state.profile.users_whatsapps.is_verified = payload.verify;
    },
    SET_EMAIL_VERIFY(state, payload) {
      state.profile.users_emails.is_verified = payload.verify;
    },
    // notif
    SET_NOTIF_COUNT(state, payload) {
      state.notif.count = payload.count;
    },
  },
  actions: {
    // token
    setAuthToken ({ commit }, payload) {
      commit("SET_AUTH_TOKEN", payload);
    },
    removeCurrentUser ({ commit }) {
      commit('REMOVE_TOKEN')
    },
    // profile
    setProfileUser  ({ commit }, payload)  {
      commit("SET_PROFILE_USER", payload);
    },
    setProfileId  ({ commit }, payload)  {
      commit("SET_PROFILE_ID", payload);
    },
    setProfileAvatar  ({ commit }, payload)  {
      commit("SET_PROFILE_AVATAR", payload);
    },
    setProfileBiodata  ({ commit }, payload)  {
      commit("SET_PROFILE_BIODATA", payload);
    },
    setProfileRemove  ({ commit })  {
      commit("REMOVE_PROFILE");
    },
    setProfileEmail ({ commit }, payload)  {
      commit("SET_PROFILE_EMAIL", payload);
    },
    setProfileWhatsapp ({ commit }, payload)  {
      commit("SET_PROFILE_WHATSAPP", payload);
    },
    setWhatsappVerify ({ commit }, payload)  {
      commit("SET_WHATSAPP_VERIFY", payload);
    },
    setEmailVerify ({ commit }, payload)  {
      commit("SET_EMAIL_VERIFY", payload);
    },
     // notif
     setNotifCount({ commit }, payload) {
      commit("SET_NOTIF_COUNT", payload);
    },
  },
  plugins: [
    createPersistedState({
      key: "rocknet",
      paths: [
        "token",
        "profile",
        "notif",
      ],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: expiredTime, secure: false }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
});
