import axios from "axios"
import store from "../store"

const authKey = store.state.token

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})


api.interceptors.request.use(async (config) => {
   config.headers['Authorization'] = authKey;
  return config;
}, (error) => {
  return Promise.reject(error)
});

export default api
