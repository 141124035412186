import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Login'
    }
  },
  {
    path: '/auth',
    component: () => import('@/views/Layouts/Auth/Layout.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          typePage: "pages",
        },
        component: () => import('@/views/Pages/Auth/Login.vue')
      },
      {
        path: 'register',
        name: 'Register',
        meta: {
          typePage: "pages",
        },
        component: () => import('@/views/Pages/Auth/Register.vue')
      }
    ]
  },
  {
    path: '/app',
    component: () => import('@/views/Layouts/App/Layout.vue'),
    children: [
      {
        path: 'home',
        name: 'HomeIndex',
        component: () => import('@/views/Pages/App/Home/Index.vue')
      },
      {
        path: 'transaction',
        name: 'TransactionIndex',
        component: () => import('@/views/Pages/App/Transaction/Index.vue')

      },
      {
        path: 'trouble',
        name: 'TroubleIndex',
        component: () => import('@/views/Pages/App/Trouble/Index.vue')
      },
      {
        path: 'account/',
        name: 'AccountIndex',
        component: () => import('@/views/Pages/App/Account/Index.vue')
      },
      {
        path: 'account/:modal',
        name: 'AccountIndexModal',
        component: () => import('@/views/Pages/App/Account/Index.vue')
      },
      {
        path: 'notif',
        name: 'NotifIndex',
        component: () => import('@/views/Pages/App/Notif/Index.vue')
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/Layouts/ForgotPassword/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'ForgotPassword',
        meta: {
          typePage: 'pages'
        },
        component: () => import('@/views/Pages/ForgotPassword/SendEmail.vue')
      },
      {
        path: 'reset/:token',
        name: 'ResetPassword',
        meta: {
          typePage: 'pages'
        },
        component: () => import('@/views/Pages/ForgotPassword/ResetPassword.vue')
      }
    ]
  },
  {
    path: "/not-found",
    component: () => import('@/views/Layouts/ForgotPassword/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'NotFound',
        meta: {
          typePage: 'pages'
        },
        component: () => import("@/views/NotFound.vue")
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  const { token } = store.state;

  if (to.meta.typePage !== "pages" && token === "") {
    next({ name: "Login" });
  } else if (to.meta.typePage === "pages" && token !== "") {
    next({ name: "HomeIndex" });
  } else if (to.meta.typePage === "pages" && token === "") {
    next();
  }

  next();
});

export default router
