import api from "./api";

export const listNotif = async (payload) => {
  try {
    const response = await api.get("notification", {
      params: payload
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const countNotif = async () => {
  try {
    const response = await api.get("notification/count");

    return response;
  } catch (error) {
    return error.response;
  }
};

export const readNotif = async (notifID) => {
  try {
    const response = await api.post("notification/read", {
      id: notifID
    });

    return response;
  } catch (error) {
    return error.response;
  }
};
