import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
     theme: {
        themes: {
          light: {
            
            primary: '#326DE1',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
            success: '#AAD74B',
          },
        },
      },
});
